<template>
  <div class="bg-white   rounded-lg"  style="max-height: 1024px; height: 500px;">
     <v-loading :is_loading="isloading" />
    <div class="flex justify-end">
      <div class="bg-white rounded-full h-8 w-8 grid justify-center items-center shadow-lg" @click="$emit('close')">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
       <div :class="{'block': islogin, 'grid': !islogin}" class=" text-center justify-center gap-y-10 sm:px-5 md:px-10 py-5 mt-5 items-center max-h-full w-full">
          <div class="flex justify-center">
           <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-box-arrow-in-left text-fucsia-lighter" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"/>
            <path fill-rule="evenodd" d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
            </svg>
          </div>
          <div v-if="!islogin">
            <div class="grid">
                <span class="text-2xl font-sans tracking-wide font-bold">Iniciar Sesión</span>
                <span class="text-base font-sans tracking-wide text-gray-400">{{message ? message : ''}}</span>
            </div>
           <div class="grid gap-y-4 mt-5">
            <button @click="islogin = true" class="btn-full-fucsia">Iniciar Sesión</button>
            <button @click="onAnonimo" class="btn-full-fucsia bg-blue hover:bg-blue">Continuar de Forma Anonima</button>
           </div>
          </div>
          <div v-else>
             <div class="mt-0 grid gap-y-3">
               <span class="text-2xl font-sans tracking-wide font-bold">Iniciar Sesión</span>
               <div>
                    <input v-model="$v.auth.email.$model" class="input-full-fucsia"  type="email" placeholder="Correo Electrónico">
                     <small class="text-xs text-red-600" v-show="$v.auth.email.$anyDirty && !$v.auth.email.required">* El correo es requerido</small>
               </div>
                <div class="">
                    <input v-on:keyup.enter="onLogin" id="pwd" v-model="$v.auth.password.$model" class="input-full-fucsia" type="password" placeholder="Contraseña">
                   <small class="text-xs text-red-600" v-show="$v.auth.password.$anyDirty && !$v.auth.password.required">* La contraseña es requerida</small>
                </div>
                 <div class="grid gap-y-3">
                  <button @click="onLogin" class="btn-full-fucsia">Iniciar Sesión</button>
                  <button  class="btn-full-fucsia bg-gray-600 hover:bg-gray-700" @click="onCancelLogin">Cancelar</button>
                </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AuthModel from "../../../models/auth/auth.model";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ToastMixin from '../../../mixins/vue/toastmixin';

export default {
 mixins : [validationMixin, ToastMixin],
 props: {
        open: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: "Parece que no has iniciado sesión, ingresa y obten beneficios por estar registrado"
        }
 },
 data: function() {
   return {
     islogin: false,
     isloading: false,
     auth: new AuthModel(),
   }
 },
  validations: {
        auth: {
            email: {
                required
            },
            password: {
                required
            }
        }
  },
 methods: {
     ...mapActions({
            sigIn: 'auth/sigIn',
            anonymous: 'auth/setAnonymous'
        }),
        onAnonimo: async function() {
            
            if(!this.Localuser) {
              await this.anonymous();
              if(this.Localuser) this.$emit('anonimo');
            }else {
              this.$emit('anonimo');
            }
        },
        onCancelLogin: function() {
          this.islogin = false;
          this.auth = new AuthModel();
          this.$v.$reset();
        },
        onLogin: async function() {
            this.$v.$touch();
            if(!this.$v.$anyError) {
              this.isloading = true;
              const response =  await this.sigIn(this.auth);
              this.isloading = false;
              if(response.success){
                  if(this.user) {
                      this.$emit('login');
                  }
              } else{
                    this.failure(response.mensage);
              }
            }
        }
  },
  computed: {
        ...mapGetters({
        user: 'auth/getuser',
        Localuser: 'auth/getLocal',
      }),
  },

}
</script>

<style>

</style>
<template>
  <div class="animate-fade-in-up">
       <v-loading :is_loading="isloading" />
    <!-- RECOMIENDA O NO  -->
    <div  class="flex justify-center gap-2 mt-5 mb-10" v-if="( (!isauth && isanonymous) || isauth || (!isauth && isanonymous) ) && !issuggest && !nextToReview">
        <div class="text-center w-full md:w-1/2 h-52 rounded-md shadow-md grid py-5" style="background-color: #f0f3f5;">
        <div>
            <span class="text-2xl font-sans tracking-wide">Tu opinión es <strong>importante</strong></span>
        </div>
        <div>
            <span  class="text-xl font-sans tracking-wide">¿Recomiendas <strong> {{$route.params ? $route.params.name : ''}}</strong> ?</span>
        </div>
        <div class="flex gap-5 justify-center items-center">
            <button @click="suggest(true)" class="px-10 rounded-sm py-1 shadow-sm font-sans font-bold text-lg" style="background: #ced8de;">Sí</button>
            <button  @click="suggest(false)" class="px-10 rounded-sm py-1 shadow-sm font-sans font-bold text-lg" style="background: #ced8de;">No</button>
        </div>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-12 gap-2 mt-5 mb-10" v-if="( (!isauth && isanonymous) || isauth || (!isauth && isanonymous) ) && issuggest"  >
        <div class="col-start-1 col-end-2 md:col-start-1 md:col-end-13 bg-white text-center"> 
            <h6 class="text-lg font-semibold">Escribe una reseña sobre este lugar</h6> 
            <h6 class="text-sm font-sans">Comparte tu opinión con otros clientes</h6>
        </div>
         <div class="col-start-1 col-end-2 md:col-start-3 md:col-end-11 bg-white text-center" v-if="!isauth" > 
            <input :disabled="isloading" v-model="$v.review.name.$model" class="input-full-fucsia"  placeholder="Ingresa tu nombre" />
            <div class="w-full flex">
               <small class="text-error text-left w-full" v-show="$v.review.name.$anyDirty && !$v.review.name.required">* El nombre es requerido</small>
            </div>
         </div>

        <div class="col-start-1 col-end-2 md:col-start-3 md:col-end-11 bg-white text-center"> 
            <textarea hide-details v-model="$v.review.description.$model" class="input-full-fucsia" placeholder="Comparte tu experiencia con este lugar"  label="Rebyu :" rows="3"></textarea>
             <div class="flex justify-between">
                <div>         
                    <small class="text-error" v-show="$v.review.description.$anyDirty && !$v.review.description.required">* La descripción es requerida</small>
                    <small class="text-error" v-show="$v.review.description.$anyDirty && !$v.review.description.minLength">* La descripción debe contener un mínimo de {{$v.review.description.$params.minLength.min}} caracteres</small>
                    <small class="text-error" v-show="$v.review.description.$anyDirty && !$v.review.description.maxLength">* La descripción debe contener un máximo de {{$v.review.description.$params.maxLength.max}} caracteres</small>
                </div>
                <div>
                    <small>{{ review.description.length +' / '+$v.review.description.$params.maxLength.max }}</small>
                </div>
            </div>
        </div>

        <div class="col-start-1 col-end-2 md:col-start-3 md:col-end-11 bg-white text-center -mt-3"> 
            <div class="flex gap-5 justify-center col-span-8">
                <button @click="onCancelRebyu" class="btn-custom mx-0 bg-gray-200 hover:bg-gray-300 text-blue shadow-md">Cambiar</button>
                <button @click="save" class="btn-custom mx-0 bg-gray-200 hover:bg-gray-300 text-blue shadow-md">Enviar rebyu</button>
            </div>
        </div>

    </div>

    <div class="grid grid-cols-1 md:grid-cols-12 gap-4">

        <div class="col-start-1 col-end-2 md:col-start-1 md:col-end-13 text-center">
            <h6 class="text-2xl font-thin text-blue">Últimas Rebyus</h6> 
        </div>

        <div class="col-span-1 col-end-2 md:col-start-3 md:col-end-11" >
            <div class="max-w-full">
                <div class="grid gap-y-5 md:gap-y-2" >
                    
                    <div class="flex py-5 sm:px-0 md:px-20 " :class="{'justify-end': i%2==0, 'justify-start': i%2!=0 }" v-for="(rebyu, i) in reviews" :key="i">
                            <v-left-rebyu :rebyu="rebyu" :name="name" v-if="i%2!=0" />
                            <v-right-rebyu :rebyu="rebyu" :name="name" v-if="i%2==0" />
                    </div>

                </div>
            </div>
        </div>

      </div>
    <div class="text-center font-sans tracking-wide text-sm lg:text-lg text-gray-400"  v-if="!reviews || reviews.length <= 0">
        <span>Se el primero en realizar una Rebyu a la tienda !!</span>
    </div>

    <div class="grid grid-cols-12 gap-4 mt-5 bottom-auto" v-if="reviews && reviews.length > 5">
        <div class="col-start-1 col-end-13 bg-white text-center"> 
            <button class="w-9/12 h-12 px-6 text-gray-600 transition-colors duration-150 bg-gray-100 rounded-lg focus:shadow-outline hover:bg-gray-200 opacity-95 text-xl ">
                Ver  más
            </button>
        </div>
    </div>
     
    <v-dialog 
        v-model="isrequestlogin"
        persistent
        max-width="400px"
        :retain-focus="false"
        >
        <v-request-login :open="isrequestlogin" v-on:close="isrequestlogin = false" v-on:anonimo="onAnonimo" v-on:login="onLogin" />
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import ToastMixin from '@/mixins/vue/toastmixin';
import {mapGetters } from "vuex";
import Review from '../../../models/review/review.model';
import { ProfileService } from '../../../services/HSuite/Context/hsuite-rebyu-context';
import reviewService from '../../../services/review/review.service';
import requestlogin from '../../session/components/requestlogin.vue';
import leftrebyu from './components/leftrebyu.vue';
import righttrebyu from './components/rightrebyu.vue';

  export default {
    mixins: [ToastMixin, validationMixin],
    props: {
        id_entity: {
            type: String,
            default: ""
        },
        code: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        'v-request-login' : requestlogin,
        'v-left-rebyu' : leftrebyu,
        'v-right-rebyu' : righttrebyu
    },
    data () {
      return {
        isloading: false,
        profile: null,
        WindowHeight:'',
        WindowWidth:'',
        textrebyu:'',
        derechaizquierda:true,
        isanonymous: false,
        isrequestlogin: false,
        issuggest: false,
        reviews: [],
        review: new Review()
      }
    },
    validations: {
        review: {
            name: {
                required
            },
            description: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(200)
            },
            id_entity: {
                required
            },
            suggest: {
                required
            }
        }
    },
    created(){
        this.winWidth()
    },
    mounted() {
        window.onresize = () => {
            this.WindowWidth = document.documentElement.clientWidth;
            this.WindowHeight = document.documentElement.clientHeight;
        };
        this.init();
    },
    methods: {
        //define below method first.
        winWidth: function () {

            this.WindowWidth = screen.width;
            this.WindowHeight = screen.height;

        },
        init: async function() {
            
            this.isloading = true;
            this.review = new Review();
            if(this.isauth) {
                await this.getProfile();
            }else if(!this.isauth && this.code) {
                this.isrequestlogin = true;
            }
            await this.getEntityReviews(this.id_entity);
            this.isloading = false;
        },
        initReview: async function() {
             this.isloading = true;
             this.isrequestlogin = false;
             this.isanonymous = false;
             this.issuggest = false;
             this.review = new Review();
             await this.getEntityReviews(this.id_entity);
             this.isloading = false;
        },
        getProfile: async function() {
            let response = await ProfileService.getByIdUser();
            if(response.success) {
                this.profile = response.data;
            }else {
                this.failure(response.message);
            }
        },
        getEntityReviews: async function(id) {
            let response = await reviewService.getByIdEntity(id)
            if(response.success) {
                this.reviews = response.data;
            }else {
                this.failure(response.message);
            }
        },
        save: async function() {

            if(this.isauth) {
                this.review.name = this.profile ? `${this.profile.name} ${this.profile.last_name} ${this.profile.second_lastname}`: null;
                this.review.id_user = this.profile ? this.profile.idUser : null;
                this.review.photo_user = this.profile ? this.profile.profilePicture : null;
                this.review.user_date = this.profile ? this.profile.created_at : null;
                this.review.hassession =  true;
            }else {
                 this.review.id_user = this.localuser ? this.localuser : null;
                 this.review.hassession =  false;
            }

            this.review.id_entity =  this.id_entity;
            this.$v.$touch();
            if(!this.$v.$anyError) {
                this.isloading = true;
                let response = await reviewService.save(this.review);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.initReview();
                    this.$v.$reset();

                }else {
                    this.failure(response.message);
                }
            }
        },
        onAnonimo(){
            this.isrequestlogin = false;
            this.isanonymous = true;
        },
        onLogin: function(){
            this.isrequestlogin = false;
            this.init();
        },
        suggest: function (value) {
            this.review.suggest = value;
            this.issuggest = true;
        },
        onCancelRebyu: function(){
             this.review.suggest = false;
              this.issuggest = false;
              this.$v.$reset();
        }


    },
  
    computed: {
        ...mapGetters({
        isauth: 'auth/isAuth',
        localuser: 'auth/getLocal',
        }),
        nextToReview: function () {
            let fn = this.reviews.find(x=> x.idUser == (this.isauth && this.profile ? this.profile.idUser : this.localuser));
            return fn ? true : false;
        }

    }
  }
</script>

<style>

</style>
<template>
<div :class="blur" class="min-h-screen sm:px-0 md:px-2 py-4 bg-gray-100">
   <v-loading :is_loading="isloading" />
   <div class="bg-white shadow-md">
      <div class=" items-center justify-center" >
         <v-img class="h-64 w-full md:h-64 md:w-auto text-center"   :src="entity.CoverPhoto">
            <button @click="openHours" type="button" class="z-50 text-none  rounded-r-0 rounded-l v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small" style="background-color: rgba(255, 255, 255, 0.8); border-color: rgba(255, 255, 255, 0.8); position: absolute; top: 140px; right: 0px;">
               <span class="">
                  <div class="text-left font-semibold">
                     <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"><span class="relative inline-flex rounded-full h-3 w-3 bg-green-500 animate-ping"></span></span>
                     Abierto
                  </div>
               </span>
            </button>
            <div :class="cover">
               <div class="hidden md:grid md:grid-cols-7 gap-5 text-center items-center justify-center">
                  <div :class="days.class" v-for="days in ListDays.days" :key="days.day">
                     <p v-if="days.horario == 'N/A'" class="text-white font-semibold text-lg mt-10">
                        <span class="relative inline-flex rounded-full h-3 w-3 bg-red-700"><span class="relative inline-flex rounded-full h-3 w-3 bg-red-700 animate-ping"></span></span>
                        {{days.day}}
                     </p>
                     <p v-else class="text-white font-semibold text-lg mt-10">
                        <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"><span class="relative inline-flex rounded-full h-3 w-3 bg-green-500 animate-ping"></span></span>
                        {{days.day}}
                     </p>
                     <p class="ml-2 -mt-2 text-white">{{days.Text}}</p>
                     <p class="ml-4 -mt-2 text-white">{{days.horario}}</p>
                  </div>
               </div>
            </div>
            <div class="bg-white rounded-r-lg theme--light" style="background-color:white; border-color: white; position: absolute; top: 140px; left: 0px;" >
               <v-rating background-color="grey" color="warning" icon-label="custom icon label text {0} of {1}" hover length="5" size="15" :value="1"></v-rating>
            </div>
         </v-img>
         <div class="text-center mb-4">
            <v-avatar class="-mt-20" size="150">
               <v-img class="border-2 border-white" :src="entity.ProfilePicture">
               </v-img>
            </v-avatar>
         </div>
      </div>
      <div class="justify-center text-center -mt-4 mb-2">
         <h1 class="font-weight-bold text-3xl text-black">
            {{entity.name}}
         </h1>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-12  py-4 gap-y-3 animate-fade-in-down">
         <div class="flex gap-1 md:gap-5 justify-center text-center col-span-1 md:col-span-9 sm:ml-0 md:ml-96">
            <div >
               <div class="flex gap-2 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-person-check text-gray-400" viewBox="0 0 16 16">
                     <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                     <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                  </svg>
                  <div class="sm:grid md:flex lg:flex">
                     <span class="font-sans font-bold text-gray-500 tracking-wide">Seguidores: </span>
                     <span class="font-sans font-extrabold text-black tracking-wide pl-1">{{entity.followers ? entity.followers : '0'}}</span>
                  </div>
               </div>
            </div>
            <div>
               <div class="flex gap-2 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-up text-gray-400" viewBox="0 0 16 16">
                     <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
                  </svg>
                  <div class="sm:grid md:flex lg:flex">
                     <span class="font-sans font-bold text-gray-500 tracking-wide">Me Gusta: </span>
                     <span class="font-sans font-extrabold text-black tracking-wide pl-1">{{entity.likes ? entity.likes : '0'}}</span>
                  </div>
               </div>
            </div>
            <div>
               <div class="flex gap-2 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-thumbs-down text-gray-400" viewBox="0 0 16 16">
                     <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964l-.261.065zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1z"/>
                  </svg>
                  <div class="sm:grid md:flex lg:flex">
                     <span class="font-sans font-bold text-gray-500 tracking-wide">No Me Gusta: </span>
                     <span class="font-sans font-extrabold text-black tracking-wide pl-1">{{entity.dislikes ? entity.dislikes : '0'}}</span>
                  </div>
               </div>
            </div>
         </div>
         <div class="flex justify-end items-center px-10 w-full text-center col-span-1 md:col-span-3 gap-5">
            <div @click="onFollow(!entity.has_followed, type_follow.FOLLOWER)" :class="{'btn-full-fucsia cursor-pointer': !entity.has_followed, 'transition duration-500 ease-in-out  btn-full-fucsia-outline cursor-pointer': entity.has_followed}">
               {{!entity.has_followed ? 'Seguir' : 'Siguiendo'}}
            </div>
            <v-menu
               transition="slide-y-transition"
               bottom
               >
               <template v-slot:activator="{ on, attrs }">
                  <div  v-bind="attrs" v-on="on" class="w-9 h-9 px-2 flex justify-center items-center rounded-full border border-gray-400 hover:border-control-dark-blue-light">
                     <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                     </svg>
                  </div>
               </template>
               <v-list>
                  <v-list-item
                     v-for="(item, i) in follower_options"
                     :key="i"
                     class="group hover:bg-pink-100 cursor-pointer "
                     @click="onFollow(item.type == type_follow.FOLLOWER ? !entity.has_followed : 
                     item.type == type_follow.LIKE ? !entity.has_liked :  
                     item.type == type_follow.DISLIKE ? !entity.has_disliked : false, item.type)"
                     >
                     <v-list-item-icon>
                        <f-icon :icon="['fas',  item.icon]" class="text-gray-400 group-hover:text-pink-600"
                           v-bind:class="[(item.type == type_follow.FOLLOWER && entity.has_followed) ? 'text-pink-600' : '', 
                           (item.type == type_follow.LIKE && entity.has_liked) ? 'text-pink-600' : '',
                           (item.type == type_follow.DISLIKE && entity.has_disliked)  ? 'text-pink-600' : '']" />
                     </v-list-item-icon>
                     <v-list-item-title class="group-hover:text-pink-600 text-gray-500" >
                        {{ item.type == type_follow.FOLLOWER && entity.has_followed ? 'Siguiendo': item.title }}
                     </v-list-item-title>
                  </v-list-item>
               </v-list>
            </v-menu>
         </div>
      </div>
   </div>
   <hr>
   <!-- START TAB BAR -->
   <v-tabs v-model="tab" color="#336666" grow v-if="configuration && configuration.length > 0">
      <v-tab  v-for="(item, i)  in configuration" :key="i" @click="changeTab(item.code)">
         {{item.name ? item.name : ''}}
      </v-tab>
   </v-tabs>
   <v-tabs-items v-model="tab" ref="tabmenu" v-if="entity" @change="changeTab">
      <v-tab-item  key="SYST_INFORMATION" v-if="information" >
         <v-card color="#FFFFFF" flat>
            <v-card-text>
               <v-rebyu-information v-if="entity" :objentity="entity"/>
            </v-card-text>
         </v-card>
      </v-tab-item>
      <v-tab-item key="SYST_MENU" v-if="menu">
         <v-card color="#FFFFFF" flat>
            <v-card-text>
               <v-rebyu-menu  :categories="menu_category.categories"/>
            </v-card-text>
         </v-card>
      </v-tab-item>
      <v-tab-item key="SYST_PRODUCTS" v-if="producto">
         <v-card color="#FFFFFF" flat>
            <v-card-text>
               PRODUCTOS
            </v-card-text>
         </v-card>
      </v-tab-item>
      <v-tab-item key="SYST_SERVICE" v-if="servicio">
         <v-card color="#FFFFFF" flat>
            <v-card-text>
               <v-rebyu-service :categories="services" />
            </v-card-text>
         </v-card>
      </v-tab-item>
      <v-tab-item key="SYST_REBYU" v-if="rebyu" ref="tabrebyu">
         <v-card color="#FFFFFF" flat>
            <v-card-text>
               <v-rebyu-coment v-if="current_tab == 'SYST_REBYU'" :id_entity="entity.id" :code="sharedlinkcode" :name="name" />
            </v-card-text>
         </v-card>
      </v-tab-item>
   </v-tabs-items>
   <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      transition="dialog-top-transition"
      style="bord"
      >
      <v-card class="opacity-90" style="background-color:black; border-color: black;">
         <div v-for="days in ListDays.days" :key="days.day" class="grid grid-cols-1 text-center items-center justify-center h-full w-full" >
            <div class="col-start-1 col-end-2 text-center mt-3 animate-fade-in-left" >
               <p v-if="days.horario == 'N/A'" class="text-white font-semibold text-sm">
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-red-700"><span class="relative inline-flex rounded-full h-3 w-3 bg-red-700 animate-ping"></span></span>
                  {{days.day}}
               </p>
               <p v-else class="text-white font-semibold text-sm">
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"><span class="relative inline-flex rounded-full h-3 w-3 bg-green-500 animate-ping"></span></span>
                  {{days.day}}
               </p>
               <p class="text-white ">{{days.Text}}</p>
               <p class=" text-white" >{{days.horario}}</p>
            </div>
         </div>
         <div class="col-start-1 col-end-2 text-center mt-2 h-full">
            <v-btn
               class="animate-bounce  inset-x-0 bottom-auto"
               icon
               dark
               @click="hideMobileHours()"
               >
               <v-icon size="30">mdi-arrow-up</v-icon>
            </v-btn>
         </div>
      </v-card>
   </v-dialog>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import Informacion from './store/informacion.vue'
import Menu from './store/menu.vue';
import Rebyus from './store/Rebyus.vue';
import Service from './store/service.vue';
// import entityService from '../../services/entity/entity.service';
import { EntityService } from '../../services/HSuite/Context/hsuite-rebyu-context'
import entityServiceServices from '../../services/HSuite/HRebyu/service/servicios';
import ToastMixin from '../../mixins/vue/toastmixin.js';
import globalMixin from '../../mixins/globalMixin';
import EntityFollower from '../../models/entity/entityfollower.model';
import typefollower from '../../utils/common/typefollower';
import entityFollowerService from '../../services/entity/entityfollower.service';


export default {
  mixins: [ToastMixin, globalMixin],
  name: 'ProfileEntity',
  components: {
    'v-rebyu-menu':        Menu,
    'v-rebyu-information': Informacion,
    'v-rebyu-coment':      Rebyus,
    'v-rebyu-service':     Service
   },
  data() {
    return {
      tab:'',
      imgh:'img/subheader-1.jpg',
      cover:'md:bg-black md:h-full md:opacity-80 md:transform md:-translate-y-80 md:transition md:duration-500 md:delay-300',
      WindowHeight:     '',
      WindowWidth:      '',
      blur:             '',
      sharedlinkcode:   '',
      open:             false,
      dialog:           false,
      notifications:    false,
      sound:            true,
      isloading:        false,
      entity: {},
      tags:{},
      ListDays:
        {
          'days':[
            {
              'day':'Lunes',
              'Text':'Abierto',
              'horario':'8:00am - 4:00pm',
              'class':'col-start-1 col-end-2 text-center'
            },
            {
              'day':'Martes',
              'Text':'Abierto',
              'horario':'8:00am - 4:00pm',
              'class':'col-start-2 col-end-3 text-center2'
            },
            {
              'day':'Miercoles',
              'Text':'Abierto',
              'horario':'8:00am - 4:00pm',
              'class':'col-start-3 col-end-4 text-center'
            },
            {
              'day':'Jueves',
              'Text':'Abierto',
              'horario':'8:00am - 4:00pm',
              'class':'col-start-4 col-end-5 text-center'
            },
            {
              'day':'Viernes',
              'Text':'Abierto',
              'horario':'8:00am - 4:00pm',
              'class':'col-start-5 col-end-6 text-center'
            },
            {
              'day':'Sabado',
              'Text':'Abierto',
              'horario':'8:00am - 2:00pm',
              'class':'col-start-6 col-end-7 text-center'
            },
            {
              'day':'Domingo',
              'Text':'Cerrado',
              'horario':'N/A',
              'class':'col-start-7 col-end-8 text-center'
            },
          ]
      },
      services:         [],
      menu_category:    [],
      entity_config:    [],
      follower_options: [
        { title: 'Seguir',    type: 1, icon: 'user-check'},
        { title: 'Like',      type: 2, icon: 'thumbs-up'},
        { title: 'Dislike',   type: 3, icon: 'thumbs-down'},
      ],
      current_tab:      null,
      entity_follower:  new EntityFollower(),
      type_follow:      typefollower,
    }
  },
  props: {
    name: {
        type:     String,
        default:  ""
    }
  },
  created()
  {
    this.winWidth();
  },
  mounted() {
    this.init();
    window.onresize = () => {
      this.WindowWidth     = document.documentElement.clientWidth;
      this.WindowHeight    = document.documentElement.clientHeight;
    };
   if(this.$route.query.x && this.$route.query.c) {
     this.current_tab     = this.$route.query.x == 'LINK_REVIEW' ?  'SYST_REBYU' : '';
     this.tab             = this.$route.query.x == 'LINK_REVIEW' ? 2 : 0;
     this.sharedlinkcode  = this.$route.query.c;
   }

  },

  methods:{
    init: async function() {
      await this.getEntity(this.name, this.isAuth ? this.user.iduser : '');
      await this.getStoreCategories(this.name)
      await this.getStoreServices(this.entity.id)
    },
    getEntity: async function(id, id_user) {
        let response = await EntityService.getInfoTagName(id, id_user);
        if(response.success) {
            this.entity = response.data;
            
        }else {
            this.failure(response.message);
            window.location.href = '/home';
        }
    },
    getTagsEntity: async function(id) {
        let response = await EntityService.getTagsByEntity(id);
        if(response.success) {                
            this.entity.tags = response.data;
        }
    },
    getStoreCategories: async function(id) {
      let response = await EntityService.getStoreCategories(id);
      if(response.success) {
          this.entity_config = response.data;
          this.menu_category = response.data.find(x => x.code == 'SYST_MENU');
      }else {
        this.failure(response.message);
      }
    },
    getStoreServices: async function(id) {
      let response = await entityServiceServices.getByEntity(id);
      console.log(response)
      if(response.success) {
          this.services = response.data;
      }else {
        this.failure(response.message);
      }
    },
    openHours: function(){
      if(this.WindowWidth > 797){
          if(this.cover == 'md:bg-black md:h-full md:opacity-80 md:transform md:translate-y-0 md:transition md:duration-500 md:delay-300')
            this.cover  ='md:bg-black md:h-full md:opacity-80 md:transform md:-translate-y-80 md:transition md:duration-500 md:delay-300'
          else
            this.cover  ='md:bg-black md:h-full md:opacity-80 md:transform md:translate-y-0 md:transition md:duration-500 md:delay-300'
        }else{
          this.dialog = true
          this.blur   = 'filter blur-sm'
        }
    },
    winWidth: function(){
        this.WindowWidth  = screen.width;
        this.WindowHeight = screen.height;
    },
    hideMobileHours: function(){
      this.dialog = false
      this.blur   = ''
    },
    changeTab: function (event)
    {
      this.current_tab = event;
    },
    saveFollower: async function () {
      let response = await entityFollowerService.save(this.entity_follower);
      if(response.success) {
        this.success(response.message);
        this.init();
      }else {
        this.failure(response.message);
      }
    },
    onFollow: async function (value, type) {
      if(this.isAuth) {
          this.entity_follower.type       = type;
          this.entity_follower.value      = value;
          this.entity_follower.id_entity  = this.entity.id;
          switch(type) {
            case this.type_follow.FOLLOWER: 
              this.entity.has_followed  = value;
            break;
            case this.type_follow.LIKE: 
              this.entity.has_disliked  = false;
              this.entity.has_liked     = value;
            break;
            case this.type_follow.DISLIKE: 
              this.entity.has_disliked  = value;
              this.entity.has_liked     = false;
            break;
          }

          this.isloading  = true;
          await this.saveFollower();
          this.isloading  = false;
      }else {
        let confirm = await this.confirm('Iniciar Sesión', 'Es necesario iniciar sesión para continuar');
        if(confirm) {
          window.location.href = '/login';
        }
      }
    },
    orderNow: function(){
     window.location.href = 'https://wa.me/52'+this.entity.whatsapp+'?text=Hola, vi el menú en Rebyu y me gustaría ordenar: ' 
    }   
  },
  computed: {
    configuration: function () {
      let conf_active =  this.entity_config.filter(x => x.activo == 1);
      return conf_active ? conf_active : []
    },
    information: function () {
      let exist =  this.configuration.find(x => x.code == 'SYST_INFORMATION');
      return exist ? true : false;
    },
    menu: function () {
      let exist =  this.configuration.find(x => x.code == 'SYST_MENU');
      return exist ? true : false;
    },
    producto: function () {
      let exist =  this.configuration.find(x => x.code == 'SYST_PRODUCTS');
      return exist ? true : false;
    },
    rebyu: function () {
      let exist =  this.configuration.find(x => x.code == 'SYST_REBYU');
      return exist ? true : false;
    },
    servicio: function () {
      let exist =  this.configuration.find(x => x.code == 'SYST_SERVICE');
      return exist ? true : false;
    },
    ...mapGetters({
        user: 'auth/getuser',
        isAuth: 'auth/isAuth',
    }),

  },
  watch: {

  }
}



</script>

<style scoped>


</style>
<template>
<div class="m-1">
   <div v-for="(item,i) in data_categories" :key="i" class="mb-10 rounded-lg animate-fade-in-up"  >
      <div v-if="i%2==0" class="grid grid-cols-1 md:grid-cols-12 md:gap-2 mt-2" >
         <div :id="item.name" class="col-start-1 col-end-2 md:col-start-1 md:col-end-13 text-center font-thin text-blue text-2xl mb-5 mt-2 uppercase" style="letter-spacing: 5px;">
            {{item.name}}
         </div>
         <div class="h-96  col-start-1 col-end-2 md:h-128 md:w-full md:col-start-1 md:col-end-4">
            <div class="bg-cover bg-center md:rounded-l-lg md:bg-cover w-full h-full" :style="`background-image: url('`+item.image+`')`"></div>
         </div>
         <div class="col-start-1 col-end-2  md:col-start-4 md:col-end-13">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-10 gap-y-1 md:gap-y-10">
               <div class="rounded-lg mt-5 sm:ml-0 md:ml-3 sm:mr-0 md:mr-3  animate-fade-in-up " v-for="(child,x) in item.products" :key="x">
                  <div class="mb-2 text-sm table" >
                     <div class="table-cell whitespace-nowrap font-bold text-black" color="#222 " >
                        {{child.name}}
                     </div>
                     <div class="table-cell border-b-2 border-dashed w-full" ></div>
                     <div class="table-cell whitespace-nowrap text-right font-bold text-fucsia-lighter" >{{child.price | currency}}</div>
                  </div>
                  <div class="mb-4">
                     {{child.description}} 
                  </div>
                  <div>
                     <button @click="getComplements(child.id)" class="rounded-full bg-green-500 px-2 py-1 text-white"> <f-icon :icon="['fas', 'plus']"></f-icon> </button>
                  </div>
               </div>
            </div>
            <div v-if="!item.products || item.products.length <= 0" class="h-full py-5 flex justify-center items-center text-gray-400 font-sans tracking-wider px-5 text-center">
               <span>Estamos trabajando en el menú para todos nuestros clientes</span>
            </div>
         </div>
         <div class="col-span-2 md:col-span-12">
            <v-pagination
               color="pink lighten-1"
               v-if="item.products && item.products.length > 0"
               v-model="item.page"
               :length="item.total_pages"
               :total-visible="5"
               circle
               @input="onPage(item, item.page, item.per_page,item.name)"
               ></v-pagination>
         </div>
      </div>
      <div v-else class="grid  grid-cols-1 md:grid-cols-12" >
         <div id="item.name" class="col-start-1 col-end-2 md:col-start-1 md:col-end-13 text-center font-thin text-blue text-2xl mb-5 mt-2 uppercase" style="letter-spacing: 5px;">
            {{item.name}}
         </div>
         <div v-show="WindowWidth <= 768" class="h-96 col-start-1 col-end-2 md:h-full md:w-full bg-gray-100">
            <div class="bg-cover bg-center md:rounded-r-lg md:bg-cover w-full h-full"  :style="`background-image: url('`+item.image+`')`">
            </div>
         </div>
         <div class="col-start-1 col-end-2 md:col-start-1 md:col-end-10 bg-gray-100">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-10 gap-y-1 md:gap-y-10">
               <div class="rounded-lg mt-5 ml-3 mr-3" v-for="(child,x) in item.products" :key="x">
                  <div class="mb-2 text-sm table  " >
                     <div class="table-cell whitespace-nowrap font-bold text-black" color="#222 " >
                        {{child.name}}	
                     </div>
                     <div class="table-cell border-b-2 border-dashed w-full" ></div>
                     <div class="table-cell whitespace-nowrap text-right font-bold text-fucsia-lighter" >{{child.price | currency}}</div>
                  </div>
                  <div class="mb-4">
                     {{child.description}} 
                  </div>
               </div>
            </div>
            <div v-if="!item.products || item.products.length <= 0" class="h-full py-5 flex justify-center items-center text-gray-400 font-sans tracking-wider px-5 text-center">
               <span>Estamos trabajando en el menú para todos nuestros clientes</span>
            </div>
         </div>  <!--aqui era  md:h-128-->
         <div v-show="WindowWidth > 768" class="h-96 md:h-full md:w-full md:col-start-10 md:col-end-13">
            <div class="bg-cover bg-center  md:rounded-r-lg md:bg-cover w-full h-full" :style="'background-image: url('+item.image+')'">
            </div>
         </div>
         <div class="col-span-12 md:col-span-12">
            <v-pagination
               color="pink lighten-1"
               v-if="item.products && item.products.length > 0"
               v-model="item.page"
               :length="item.total_pages"
               :total-visible="5"
               circle
               @input="onPage(item, item.page, item.per_page,item.name)"
               ></v-pagination>
         </div>
      </div>
   </div>
   <div v-if="!categories || categories.length <= 0" class="sm:h-20 md:h-40 flex justify-center items-center text-gray-400 font-sans tracking-wider px-5 text-center">
      <span>Estamos trabajando en el menú para todos nuestros clientes</span>
   </div>
</div>
</template>
<script>
import { MenuService } from '../../../services/HSuite/Context/hsuite-product-context';

  export default {
    props: {
        categories: {
            type: Array,
            default: function() {return []}
        }
    },
    data () {
      return {
        WindowHeight:       '',
        WindowWidth:        '',
        data_categories:    this.categories
      }
    },
    created(){
        this.winWidth()
    },
    mounted() {
     window.onresize = () => {
        this.WindowWidth    = document.documentElement.clientWidth;
        this.WindowHeight   = document.documentElement.clientHeight;
     };
    },
    methods: {
        winWidth: function () {
            this.WindowWidth    = screen.width;
            this.WindowHeight   = screen.height;
        },
        getProducts: async function(item, id_category, id_entity, page, limit) {
            let response = await MenuService.getProductsByCategoryStore(id_category, id_entity, page, limit);
            if(response.success) {
               item.products = response.data.data;
            }else {
                this.failure(response.message);
            }
        },
        onPage: async function(item, page, limit, iddiv) {
            document.getElementById(iddiv).scrollIntoView();
            await this.getProducts(item, item.id, item.id_entity, page, limit);
        },
        getComplements: async function(id) {
            let response = await MenuService.getComplementSummary(id);
            if(response.success) {
               console.log("RESPONSE", response);
            }
        }
    },

  }
</script>
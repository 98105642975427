<template>
     <div class="bg-gray-100 rounded-md shadow-lg w-full md:w-4/6 md:ml-10 " >

        <div class="grid grid-cols-1 md:grid-cols-12 sm:gap-0 md:gap-1">
            <div class="grid md:flex items-center md:items-end col-start-1 col-end-2 md:col-start-1 md:col-end-2  gap-2 md:gap-10 py-2">
               <div class="text-center block md:hidden">
                    <v-avatar class="-mt-10 md:-ml-10 md:mt-0 shadow-2xl border-4 border-white " size="80">
                        <v-img class="rounded-full" :src="rebyu.photo" v-if="rebyu.hassession"></v-img>
                        <div v-else class="px-2 py-2 bg-red-primary w-full h-full rounded-full">
                            <svg  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-800 opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                        </div>
                    </v-avatar>
                </div>
               <div  class="text-center md:float-left md:-ml-10 hidden md:block">
                <div class="inline-flex shadow-xl">
                    <button class="px-1 py-1 rounded-l transition duration-500 ease-in-out bg-indigo-100 hover:bg-like-hover w-14 text-center md:w-10">
                        <v-icon color="#AED6F1"  size="20">mdi-thumb-up</v-icon>
                    </button>
                    <button class="px-1 rounded-r transition duration-500 ease-in-out bg-red-200 hover:bg-red-300 hover:text-red-50 w-14 text-center md:w-10">
                        <v-icon color="#e01f3f"  size="20">mdi-thumb-down</v-icon>
                    </button>
                </div>
            </div>
            </div>
            <div class="grid md:block items-center col-start-1 col-end-2 md:col-start-2 md:col-end-12  gap-2 md:gap-10 md:justify-end" >
                  <div class="grid md:block justify-center md:justify-end">
                      <div class="sm:text-center md:text-right">
                        <span class="font-light font-sans tracking-wider sm:text-base md:text-xl"><strong class="font-bold">{{rebyu.name ? rebyu.name : ''}}</strong> {{`${rebyu.suggest ? '': 'no'} recomienda`}} <strong class="font-bold">{{name ? name : ''}}</strong></span>
                      </div>
                        <div class="grid sm:justify-center md:justify-end">
                            <div class="grid justify-end">
                                <div class="grid sm:justify-center md:justify-end">
                                    <div v-if="rebyu.hassession" class="md:w-min flex gap-2 items-center  justify-center bg-gradient-to-t from-red-600 to-red-primary text-white px-2 rounded-lg font-sans tracking-wider">
                                        <f-icon :icon="['fas', 'newspaper']" />
                                        <span class="font-semibold">Reseñador</span>
                                    </div>
                                </div>
                                <div class="w-max grid gap-2 mt-1">
                                    <div class="flex gap-2" v-if="rebyu.hassession">
                                        <f-icon class="text-gray-400" :icon="['fas', 'star']" />
                                        <span class="text-xs tracking-wider text-gray-400 font-sans">Cliente activo desde {{rebyu.user_year ? rebyu.user_year : new Date().getFullYear() }}</span>
                                    </div>
                                    <div class="flex" v-else>
                                            <span class="text-xs tracking-wider text-gray-400 font-sans">Cliente no registrado</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 flex sm:justify-center md:justify-end gap-5 py-2 md:py-1">
                            <div class="">
                                <h6 class="font-semibold -mt-3 text-like-hover"> <v-icon color="#A6A5A5"  size="15">mdi-thumb-up</v-icon> {{rebyu.util ? rebyu.util  : '0' }}</h6> 
                            </div>
                            <h6 class="font-semibold -mt-3">|</h6> 
                             <div class="">
                                <h6 class="font-semibold -mt-3 text-dislike-hover"><v-icon color="#A6A5A5"  size="15">mdi-thumb-down</v-icon> {{rebyu.noutil ? rebyu.noutil  : '0' }}</h6>
                            </div>
                            
                        </div>
                        <v-divider class="bg-blue"></v-divider>
                        <div class="">
                          <div class="text-justify">                                      
                                <p class="font-sans text-sm md:text-right mt-0 sm:text-justify">                     
                                    {{rebyu.description ? rebyu.description : ''}}
                                </p>
                            </div>
                        </div> 
                  </div>
                  
            </div>
            <div class="grid col-start-1 col-end-2 md:col-start-12 md:col-end-13 items-end md:items-center py-2">
             <div class="text-center hidden md:block">
                    <v-avatar class="-mt-10 md:-mr-10 md:mt-0 shadow-2xl border-4 border-white " size="80">
                        <v-img class="rounded-full" :src="rebyu.photo" v-if="rebyu.hassession"></v-img>
                        <div v-else class="px-2 py-2 bg-red-primary w-full h-full rounded-full">
                            <svg  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-800 opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                        </div>
                    </v-avatar>
                </div>
               <div  class="text-center block md:hidden">
                <div class="inline-flex shadow-xl">
                    <button class="px-1 py-1 rounded-l transition duration-500 ease-in-out bg-indigo-100 hover:bg-like-hover w-14 text-center md:w-10">
                        <v-icon color="#AED6F1"  size="20">mdi-thumb-up</v-icon>
                    </button>
                    <button class="px-1 rounded-r transition duration-500 ease-in-out bg-red-200 hover:bg-red-300 hover:text-red-50 w-14 text-center md:w-10">
                        <v-icon color="#e01f3f"  size="20">mdi-thumb-down</v-icon>
                    </button>
                </div>
            </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        rebyu: {
            type: Object,
            default: function() {return {}}
        },
        name: {
            type: String,
            default: ""
        }
    }

}
</script>

<style>

</style>
<template>
<!-- rebyus publicaciones vistas rango de precios -->
<!-- tags, descripcion del tag -->
<div class="animate-fade-in-up">
              <v-row class="">
                <v-col>
                   
                  
                  <div class="divide-y divide-gray-300 justify-center text-center">
                    <h5 class="font-thin text-2xl mb-2 " style=" letter-spacing: 10px;">UBICACIÓN</h5> 
                    <div class="mb-5"></div>
                  </div> 

                      <rb-direction-map :origin="location" :destination="storeLocation" v-if="storeLocation" />
                      <rb-place-map :location="location" v-else/>
                    
                      <!-- <div  class="bg-white rounded-md shadow-lg md:shadow-2xl absolute z-50 whitespace-pre-line w-72  md:w-96 md:h-24 inset-x-0 md:inset-x-10 justify-center text-center" style="top:520px;" > 
                        <h6 class="font-thin mt-2 text-base" style=" letter-spacing: 5px;">DIRECCIÓN</h6>
                        <p class="font-sans text-sm font-semibold mt-2">
                           <v-icon small>mdi-map-marker</v-icon>{{objentity.address}}
                        </p>
                      </div> -->

                </v-col>
              </v-row>


              <v-row>
                <v-col>
                  <div class="mt-20">
                    <div class="divide-y divide-gray-300 justify-center text-center">
                      <h5 class="font-thin text-2xl mb-2 " style=" letter-spacing: 10px;">GENERAL</h5> 
                      <div class="mb-5"></div>
                    </div> 
                  </div>

                  <div class="container">
                    <p class="font-sans text-lg font-semibold text-center">
                      {{objentity.description}}
                    </p>
                  </div>

                </v-col>
              </v-row>

              <v-row>
                <v-col>

                  <div class="mb-5">
                      <div class="flex justify-center">
                          <div class="bg-white rounded-lg  shadow-2xl w-96">
                              <div class="container">
                                                           <div class="text-center">
                                  <h6 class="font-thin mt-2 text-base" style=" letter-spacing: 5px;">MÉTRICAS</h6>
                                </div>
                                <p class="ml-1 font-sans text-base mt-2"><v-icon small>mdi-share-variant</v-icon> <strong>1,140 Personas</strong> recomiendan este lugar</p>
                                <p class="ml-1 font-sans text-base mt-2"><v-icon small>mdi-comment-processing</v-icon> <strong>{{objentity.total_reviews ? objentity.total_reviews : '0'}} Personas</strong> han escrito una Rebyu</p>
                                <p class="ml-1 font-sans text-base font-semibold mt-2">
                                    <v-icon small>mdi-tag-multiple</v-icon>
                                    <span v-for="(tag,index) in objentity.tags" :key="index">
                                     {{tag}} -
                                    </span>
                                 </p>
                                <p class="ml-1 font-sans text-base font-semibold mt-2"><v-icon small>mdi-cash-multiple</v-icon> $80 - $150</p>
                              </div>
                            
                          </div>
                      </div>
                  </div>
                  

                </v-col>
                <v-col>

                  <div class="mb-5">
                      <div class="flex justify-center">
                          <div class="bg-white rounded-lg  shadow-xl w-96">
                              <div class="container">
                                <div class="text-center">
                                  <h6 class="font-thin mt-2 text-base" style=" letter-spacing: 5px;">CONTACTO</h6>
                                </div>
                                <a v-if="objentity.web" class="ml-1 font-sans text-base font-semibold mt-2" target="_blank" :href="objentity.web ? 'http://'+objentity.web : '/home'" style="color:grey;"><v-icon small>mdi-web</v-icon> {{objentity.web}}</a>
                                <p v-if="objentity.telephone" class="ml-1 font-sans text-base font-semibold mt-2"><v-icon small>mdi-cellphone-text</v-icon> {{objentity.telephone}}</p>
                                <p v-if="objentity.email" class="ml-1 font-sans text-base font-semibold mt-2"><v-icon small>mdi-email</v-icon> {{objentity.email}}</p>
                                <div  class="mt-2 flex justify-center">
                                  <v-btn
                                    v-for="icon in icons"
                                    :key="icon.type"
                                    class="mx-4 bg-white"
                                    icon
                                    :href="icon.link"
                                  >
                                  
                                    <v-icon size="24px">
                                      {{ icon.type }}
                                    </v-icon>
                                  </v-btn>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>

                </v-col>
              </v-row>

</div>

</template>

<script>
import {  mapGetters } from "vuex";
// import GoogleMap from "@/components/ProfileEntity/GoogleMap";

  export default {

    components:
    {
        //  GoogleMap
    },
    props: {
        objentity: {
            type: Object,
            default: null
        }
    },

    data: () => ({
      numero:'https://wa.me/52',
      entidad :{},
    
      icons:[
        {
          'type':'mdi-facebook',
          'link':''
        },
        {
          'type':'mdi-twitter',
          'link':''
        },
        {
          'type':'mdi-instagram',
          'link':''
        },
        {
          'type':'mdi-whatsapp',
          'link':''
        }

      ]
    }),

    mounted(){
       
    },
  watch: {

        'entidad.telephone' : function(){

          this.cambiar();

        }
    },
    created()
    {
       this.entidad = this.objentity;
      
    },
    computed: {
    ...mapGetters({
      currentlocation: "geolocation/currentlocation",
    }),
    location: function() {
      return this.currentlocation.lat+','+this.currentlocation.long
    },
    storeLocation: function() {
      return this.objentity.latitude &&  this.objentity.length ? this.objentity.latitude+','+this.objentity.length: ''
    }

    },
    methods:{
      cambiar(){    
        this.numero += this.entidad.whatsapp
        this.icons[0].link = this.entidad.facebook
        this.icons[1].link = this.entidad.twitter
        this.icons[2].link = this.entidad.instagram
        this.icons[3].link = this.numero
      }
    }
  }
</script>
<style>

</style>
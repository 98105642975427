import ApiRebyu from '../config/ApiRebyu';
import api_url from '../../utils/api.url';

export default  {

    /**
     * Función que permite registrar los seguidores de una entidad
     * @param {any} data Modelo de datos de la entidad
     * @returns 
     */
         async save(data) {
            try {
                const url = api_url.APP_REBYU_ENTITY_FOLLOWER_SAVE;
                return await ApiRebyu.post(url, data);
            } catch (error) {
                return error.response.data;
            }
        }, 

    /**
     * Función que permite obtener las entidades a las que sigue el usuario
     * @returns 
     */
    async favoriteByUser() {
        try {
            const url = api_url.APP_REBYU_ENTITY_FOLLOWER_BY_USER;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    }, 
}
<template>
  <div class="m-1">

      <div  v-for="(item,i) in data_categories" :key="i" class="grid grid-cols-1 md:grid-cols-12 md:gap-2 mt-2 animate-fade-in-up" >
         <div class="col-start-1 col-end-2 md:col-start-1 md:col-end-13 text-center font-thin text-blue text-2xl mb-5 mt-2 uppercase" style="letter-spacing: 5px;">
            {{item.name}}
         </div>
         <div class="h-96  col-start-1 col-end-2 md:h-128 md:w-full md:col-start-1 md:col-end-4">
            <div class="bg-cover bg-center md:rounded-l-lg md:bg-cover w-full h-full" :style="`background-image: url('`+item.image+`')`"></div>
         </div>
         <div class="col-start-1 col-end-2  md:col-start-4 md:col-end-13 sm:mt-5 md:mt-0">

            <div class="grid sm:grid-cols-1 md2:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3  gap-10 gap-y-1 md:gap-y-10">
              <div v-for="(child,x) in item.services" :key="x" class="h-65 w-full bg-white rounded-md shadow-md border hover:shadow-lg sm:mb-4 md:mb-0">
                <div class="h-20 pt-4">
                  <h1 class="font-thin text-2xl text-blueblack text-center"  style="letter-spacing: 2px;"> {{child.name}}</h1>
                </div>
                  
                  <div class="bg-gray-100 rounded-tl-full h-48 w-full">
                    <div class="pt-7 text-center h-28 ">
                      <p class="text-sm m-2">
                      {{child.description}}
                      </p>
                    </div>
                    <div class="h-7 w-16 rounded-l-md float-right bg-fucsia-dark ">
                      <h5 class="font-semibold text-white ml-2 mt-1">$ {{ child.price}}</h5>
                    </div>
                    <div class="w-full flex justify-center">
                        <v-btn  class="ma-1" color="error" plain >
                          Reservar
                        </v-btn>
                        <v-btn @click="getComplements(child.id)" class="ma-1" color="blue darken-2" plain >
                          Ver más
                        </v-btn>
                    </div>
                  </div>
              </div>
            </div>

         </div>
      </div>

  </div>
</template>

<script>

 import  { ServicesService } from '../../../services/HSuite/Context/hsuite-product-context'
  export default {
    props: {
      categories: []
    },
    data () {
      return {
        WindowHeight:       '',
        WindowWidth:        '',
        data_categories:    this.categories
      }
    },
    created(){
        this.winWidth()
        console.log(this.data_categories)
    },
    mounted() {
     window.onresize = () => {
        this.WindowWidth    = document.documentElement.clientWidth;
        this.WindowHeight   = document.documentElement.clientHeight;
     };
    },
    methods: {
        winWidth: function () {
            this.WindowWidth    = screen.width;
            this.WindowHeight   = screen.height;
        },

        getComplements: async function(id) {
            let response = await ServicesService.getComplementSummary(id);
            if(response.success) {
               console.log("RESPONSE", response);
            }
        }
    },
  }
</script>
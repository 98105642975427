export default class EntityFollower {
    constructor(
        id_entity,
        id_user,
        type,
        description,
        value
    ) {
        this.id_entity  = id_entity ? id_entity : '',
        this.id_user =  id_user ? id_user : '',
        this.type =  type ? type : 1,
        this.description = description ? description : '',
        this.value = value ? value : false
    }
}
import ApiRebyu from "../../../config/ApiRebyu";
import { APP_REBYU_SERVICES_BY_ENTITY } from '../../../utils/apiurl';

export default {

    /**
     * Función que permite obtener el listado de servicios de una entidad
     * @param {*} id_entity ID de  la entidad
     * @param {*} limit Elementos por pagina Default 10
     * @returns 
     */
     async getByEntity(id_entity, limit = 10) {
        try {
            const url = APP_REBYU_SERVICES_BY_ENTITY + id_entity + '/' + limit;
            let res = await ApiRebyu.get(url);
            return res;
        } catch (error) {
            console.log(error);
            return error.response.data;
        }
    }
}
import ApiRebyu from '../config/ApiRebyu';
import api_url from '../../utils/api.url';

export default  {

 /**
 * Función que se encarga de guardar las reviews en el sistema
 * @param {any} data Modelo de datos de la review
 * @returns 
 */
  async save(data) {
    try {
        const url = api_url.APP_REBYU_REVIEW_SAVE;
        return await ApiRebyu.post(url, data);
    } catch (error) {
        return error.response.data;
    }
}, 

/**
 * Función que permite obtener las reviews de una entidad
 * @param {*} id ID de la entidad
 * @returns 
 */
  async getByIdEntity(id) {
    try {
        const url = api_url.APP_REBYU_REVIEW_GET_BY_ID_ENTITY + id;
        return await ApiRebyu.get(url);
    } catch (error) {
        return error.response.data;
    }
}, 

}
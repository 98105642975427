export default class Rewiew {
    constructor(
        name,
        description,
        id_entity,
        id_user,
        photo_user,
        user_date,
        suggest,
        hassession
    ) {
        this.name = name ? name : '',
        this.description = description ? description : '',
        this.id_entity = id_entity ? id_entity : '',
        this.id_user =  id_user ? id_user : '',
        this.photo_user = photo_user ? photo_user : '',
        this.user_date = user_date ? user_date : null,
        this.suggest = suggest ? suggest : false,
        this.hassession = hassession ? hassession : false
    }
}